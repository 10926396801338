import type { RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import type { MainNavigationFieldsFragment } from "@/components/contentful/main-navigation/__generated/main-navigation.contentful.generated";
import {
    Box,
    Flex,
    HStack,
    useToken,
    useDisclosure,
    Grid,
    GridItem,
    Tabs,
    TabList,
    TabPanels,
    Button,
    MotionBox,
    useMediaQuery,
    mediaQueryMinWidth,
    VStack,
} from "@project/ui";
import type { BoxProps } from "@project/ui";
import { useAppStore } from "@/store/app-store";
import { WrapperContainer } from "@/components/wrapper-container";
import { LanguageSelector } from "@/components/contentful/main-navigation/components/language-selector";
import { HighlightLink } from "@/components/contentful/main-navigation/components/highlight-link";
import type { Transition, Variants } from "framer-motion";
import { motion, useMotionValueEvent, useScroll, AnimatePresence } from "framer-motion";
import { frostedGlassStyle } from "@porsche-design-system/components-react/styles";
import {
    QuickLinkButton,
    QuickLinkIconButton,
} from "@/components/contentful/main-navigation/components/quick-link-button";
import { QuickLink } from "@/components/contentful/main-navigation/components/quick-link";
import { getHrefForPageType } from "@/common/helpers/slug";
import { RemoveScroll } from "react-remove-scroll";
import {
    SectionAccordion,
    SectionAccordionButton,
    SectionAccordionItem,
    SectionAccordionPanel,
} from "@/components/contentful/main-navigation/components/section-accordion";
import { PorscheLogo } from "@/components/porsche-logo";
import { SectionLinkCard } from "@/components/contentful/main-navigation/components/section-link-card";
import { SeriesMarquee } from "@/components/contentful/main-navigation/components/series-marquee";
import {
    SectionGrid,
    SectionGridItem,
} from "@/components/contentful/main-navigation/components/section-grid";
import { SectionImage } from "@/components/contentful/main-navigation/components/section-image";
import { SectionLink } from "@/components/contentful/main-navigation/components/section-link";
import FocusLock from "react-focus-lock";
import { Backdrop } from "@/components/contentful/main-navigation/components/backdrop";
import { breakpoints } from "@project/ui/src/design-tokens";
import { MobileTabClose } from "@/components/contentful/main-navigation/components/mobile-tab-close";
import { MotionTabPanel } from "@/components/contentful/main-navigation/components/motion-tab-panel";
import { MotionTab } from "@/components/contentful/main-navigation/components/motion-tab";
import { QuickLinks } from "@/components/contentful/main-navigation/quick-links";

const LiveTicker = dynamic(() =>
    import("@/components/contentful/live-ticker/live-ticker").then((module) => module.LiveTicker)
);

const MotionSectionLink = motion(SectionLink);
const MotionGridItem = motion(GridItem);
const MotionSectionGridItem = motion(SectionGridItem);
const MotionSectionGrid = motion(SectionGrid);

type MainNavigationProps = MainNavigationFieldsFragment;

const CUSTOM_BREAKPOINT_ABOVE_LARGE_BELOW_XLARGE = "1380px";

const DURATION = 0.5;
const EASING = [0.35, 0, 0.14, 1];
const TRANSITION: Transition = {
    duration: DURATION,
    ease: EASING,
    type: "tween",
};

const TRANSITION_WIDTH_HEIGHT_ENTER = {
    duration: 0.5,
    type: "tween",
    ease: [0.45, 0.4, 0.14, 1],
};
const TRANSITION_WIDTH_HEIGHT_EXIT = {
    duration: 0.3,
    type: "tween",
    ease: [0.62, 0, 0.14, 1],
};
const TRANSITION_ITEM_REVEAL = {
    duration: DURATION,
    ease: [0, 0.41, 0.14, 1],
    type: "tween",
};

const tabIndices = {
    series: 0,
    cars: 1,
    teams: 2,
    events: 3,
};

const animVariantsBackdrop: Variants = {
    navigationCollapsed: {
        opacity: 0,
        transition: {
            ...TRANSITION,
        },
    },
    navigationExpanded: {
        opacity: 1,
        transition: {
            ...TRANSITION,
        },
    },
};

const animVariantsMobileToolbar: Variants = {
    navigationCollapsed: {
        opacity: 0,
        transition: {
            ...TRANSITION,
        },
    },
    navigationExpanded: {
        opacity: 1,
        transition: {
            ...TRANSITION,
        },
    },
};

const getExpandedWidth = (isAboveLarge: boolean, isAboveCustomBreakpoint: boolean) => {
    if (isAboveLarge && !isAboveCustomBreakpoint) {
        return "90%";
    } else if (isAboveLarge && isAboveCustomBreakpoint) {
        return "896px";
    } else {
        return "100%";
    }
};

const animVariantsWidth = (isAboveLarge: boolean, isAboveCustomBreakpoint: boolean): Variants => ({
    navigationCollapsed: {
        width: isAboveLarge ? "auto" : "100%",
        transition: {
            ...TRANSITION_WIDTH_HEIGHT_EXIT,
        },
    },
    navigationExpanded: {
        width: getExpandedWidth(isAboveLarge, isAboveCustomBreakpoint),
        transition: {
            ...TRANSITION_WIDTH_HEIGHT_ENTER,
        },
    },
});

const animVariantsHeight = (isAboveLarge: boolean, targetHeightMobile: string): Variants => ({
    navigationCollapsed: {
        height: "var(--sizes-navQuickLinksHeight)",
        transition: {
            ...TRANSITION_WIDTH_HEIGHT_EXIT,
        },
    },
    navigationExpanded: {
        height: isAboveLarge ? "640px" : targetHeightMobile,
        transition: {
            ...TRANSITION_WIDTH_HEIGHT_ENTER,
        },
    },
});

const animVariantsInnerContent: Variants = {
    navigationCollapsed: {
        opacity: 0,
        transition: {
            ...TRANSITION_WIDTH_HEIGHT_EXIT,
        },
    },
    navigationExpanded: {
        opacity: 1,
        transition: {
            ...TRANSITION_WIDTH_HEIGHT_ENTER,
            delay: TRANSITION_WIDTH_HEIGHT_ENTER.duration / 2,
        },
    },
};

const animVariantsQuickLinks: Variants = {
    quickLinksVisible: {
        opacity: 1,
        transition: {
            ...TRANSITION,
            delay: TRANSITION_WIDTH_HEIGHT_EXIT.duration,
        },
    },
    quickLinksHidden: {
        opacity: 0,
        transition: {
            ...TRANSITION,
        },
    },
};

const animVariantsTabList: Variants = {
    tabListVisible: ({ delayChildren }: { delayChildren: boolean }) => ({
        // opacity: 1,
        transition: {
            staggerChildren: 0.05,
            delayChildren: delayChildren ? TRANSITION_WIDTH_HEIGHT_ENTER.duration / 2 : 0,
        },
    }),
    tabListHidden: {
        // opacity: 0,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
    tabListHiddenWithY: {
        // opacity: 0,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
};

const animVariantsTab = {
    tabListVisible: {
        y: 0,
        opacity: 1,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
    tabListHidden: {
        y: 0,
        opacity: 0,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
    tabListHiddenWithY: {
        y: -16,
        opacity: 0,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
};

const animVariantsTabPanels: Variants = {
    tabPanelsVisible: {},
    tabPanelsHidden: {},
};

const animVariantsTabPanel: Variants = {
    tabPanelVisible: ({ delay, isAboveLarge }: { delay: boolean; isAboveLarge: boolean }) => ({
        opacity: 1,
        transition: {
            ...TRANSITION,
            delay: isAboveLarge && delay ? 0.5 : 0,
        },
    }),
    tabPanelHidden: {
        opacity: 0,
        transition: {
            ...TRANSITION,
        },
    },
};

const animVariantsSectionGrid: Variants = {
    visible: ({
        delayChildren,
        isAboveLarge,
    }: {
        delayChildren: boolean;
        isAboveLarge: boolean;
    }) => ({
        transition: {
            staggerChildren: 0.07,
            delayChildren: delayChildren ? (isAboveLarge ? 0.7 : 0.5) : 0,
        },
    }),
    hidden: {},
};

const animVariantsSectionGridItem: Variants = {
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
    hidden: {
        y: -25,
        opacity: 0,
        transition: {
            ...TRANSITION_ITEM_REVEAL,
        },
    },
};

/**
 * Hook to detect clicks outside a given set of refs.
 * @param refs
 * @param callback
 */
const useClickOutside = (refs: RefObject<HTMLElement>[], callback: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (refs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // no need to re-register event listeners when breakpoint changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refs, callback]);
};

const useDynamicNavHeight = ({
    headSpaceTop,
    headSpaceBottom,
}: {
    headSpaceTop: number;
    headSpaceBottom: number;
}): string => {
    const [height, setHeight] = useState<string>("auto");

    const calculateHeight = useCallback(() => {
        const viewportHeight = window.innerHeight;
        const newHeight = viewportHeight - headSpaceTop - headSpaceBottom;
        return `${newHeight}px`;
    }, [headSpaceTop, headSpaceBottom]);

    useEffect(() => {
        const updateHeight = () => {
            setHeight(calculateHeight());
        };

        const debounce = (func: () => void, delay: number) => {
            let timeoutId: number;
            return () => {
                clearTimeout(timeoutId);
                timeoutId = window.setTimeout(func, delay);
            };
        };

        // Initial height calculation
        updateHeight();

        const debouncedUpdateHeight = debounce(updateHeight, 100);

        // Use ResizeObserver for efficient viewport size change detection
        const resizeObserver = new ResizeObserver(debouncedUpdateHeight);
        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [calculateHeight]);

    return height;
};

const TabScrollBox = ({ children, ...props }: BoxProps) => (
    <Box
        // px={6}
        // py={4}
        p={6}
        overflowY="auto"
        sx={{
            scrollbarWidth: "none",
            "&::webkit-scrollbar": {
                display: "none",
            },
        }}
        {...props}
    >
        {children}
    </Box>
);

// We could probably make this simpler / more navigable by splitting up the nested components,
// but on the other hand, to better have an overview of animation & orchestration, it's kept in one component.
// eslint-disable-next-line sonarjs/cognitive-complexity
export const MainNavigation = (props: MainNavigationProps) => {
    const {
        highlightLink,
        languageSelectorItemsCollection,
        journalPage,
        seriesSectionCollection,
        seriesSectionLabel,
        carsSectionCollection,
        carsSectionLabel,
        teamsSectionCollection,
        teamsSectionLabel,
        eventsSectionCollection,
        eventsSectionLabel,
    } = props;

    const {
        state: { hasLiveTicker, mainNavigationId },
    } = useAppStore();

    const [isAboveLarge] = useMediaQuery(`(min-width: ${breakpoints.l})`, {
        ssr: true,
        fallback: true,
    });
    const [isAboveCustomBreakpoint] = useMediaQuery(
        `(min-width: ${CUSTOM_BREAKPOINT_ABOVE_LARGE_BELOW_XLARGE})`,
        {
            ssr: true,
            fallback: true,
        }
    );

    const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);

    const [seriesAccordionIndex, setSeriesAccordionIndex] = useState<number | number[]>(0);
    const [eventsAccordionIndex, setEventsAccordionIndex] = useState<number | number[]>(0);

    const [isTabListVisible, setIsTabListVisible] = useState<boolean>(false);
    const [isTabPanelsVisible, setIsTabPanelsVisible] = useState<boolean>(false);
    const [shouldAnimateInsideExpandedNavigation, setShouldAnimateInsideExpandedNavigation] =
        useState<boolean>(false);

    const [mobileLogoOpacity, setMobileLogoOpacity] = useState(1);

    const {
        isOpen: isNavigationOpen,
        onOpen: onNavigationOpen,
        onClose: onNavigationClose,
    } = useDisclosure();
    const ariaId = "main-navigation-:rb:";
    const closeOnClickOutsideOfLogoSideRef = useRef<HTMLDivElement>(null);
    // we also use this ref to calculate the target width of the expanded navigation
    const closeOnClickOutsideOfMainNavigationRef = useRef<HTMLDivElement>(null);
    const closeOnClickOutsideOfLanguageSelectorMobileRef = useRef<HTMLDivElement>(null);
    const closeOnClickOutsideOfLanguageSelectorDesktopRef = useRef<HTMLDivElement>(null);

    const { scrollY } = useScroll();
    const [liveTickerHeightToken] = useToken("sizes", ["liveTickerHeight"]);

    const liveTickerHeight = parseInt(liveTickerHeightToken, 10);
    const distanceToTopMobile = 8;
    const distanceToTopDesktop = 24;

    const targetHeightForExpandedNavigation = useDynamicNavHeight({
        // + 16px for the padding on top of the language selector to top of viewport
        // + height of the language selector
        // + 16px for the padding below the language selector and the expanded navigation
        headSpaceTop: 16 + 16 + 36,
        headSpaceBottom: 16,
    });

    const [quickLinksDesktopTransformY, setQuickLinksDesktopTransformY] = useState(
        hasLiveTicker ? liveTickerHeight + distanceToTopDesktop : distanceToTopDesktop
    );
    const [quickLinksMobileTransformY, setQuickLinksMobileTransformY] = useState(
        hasLiveTicker ? liveTickerHeight : distanceToTopMobile
    );
    const [desktopLogoTransformY, setDesktopLogoTransformY] = useState(0);

    useEffect(() => {
        setQuickLinksMobileTransformY(hasLiveTicker ? liveTickerHeight : distanceToTopMobile);
        setQuickLinksDesktopTransformY(
            hasLiveTicker ? distanceToTopDesktop + liveTickerHeight : distanceToTopDesktop
        );
    }, [hasLiveTicker, liveTickerHeight, distanceToTopDesktop, distanceToTopMobile]);

    useMotionValueEvent(scrollY, "change", (scrollDistance) => {
        const liveTickerHeight = parseInt(liveTickerHeightToken, 10);

        const distanceToTrigger = hasLiveTicker ? liveTickerHeight : distanceToTopDesktop;

        if (scrollDistance > distanceToTrigger) {
            setQuickLinksDesktopTransformY(distanceToTopDesktop);
            setQuickLinksMobileTransformY(distanceToTopMobile);
        } else {
            setQuickLinksDesktopTransformY(
                hasLiveTicker ? distanceToTopDesktop + liveTickerHeight : distanceToTopDesktop
            );
            setQuickLinksMobileTransformY(hasLiveTicker ? liveTickerHeight : distanceToTopMobile);
        }

        setDesktopLogoTransformY(scrollDistance < 100 ? scrollDistance * -1 : -100);
    });

    const handleOnNavigationOpen = (tabIndex: number | undefined) => {
        setTabIndex(tabIndex ?? -1);

        if (!isAboveLarge) {
            setIsTabListVisible(tabIndex === undefined);
            setIsTabPanelsVisible(tabIndex !== undefined);
        } else {
            setIsTabListVisible(true);
            setIsTabPanelsVisible(true);
        }

        onNavigationOpen();
    };

    const handleOnNavigationClose = useCallback(() => {
        setIsTabListVisible(false);
        setIsTabPanelsVisible(false);
        onNavigationClose();
    }, [onNavigationClose]);

    const handleOnTabsChange = (index: number) => {
        setSeriesAccordionIndex(0);
        setEventsAccordionIndex(0);

        setTabIndex(index);
    };

    const handleOnTabOpen = () => {
        if (!isAboveLarge) {
            setIsTabListVisible(false);
            setIsTabPanelsVisible(true);
        }
    };
    const handleOnTabClose = () => {
        if (!isAboveLarge) {
            setTabIndex(-1);
            setIsTabListVisible(true);
            setIsTabPanelsVisible(false);
        }
    };

    const handleOnAnimationCompleteOnInnerContent = (definition: string) => {
        setShouldAnimateInsideExpandedNavigation(definition === "navigationExpanded");

        if (definition === "navigationCollapsed") {
            setTabIndex(undefined);
        }
    };

    useClickOutside(
        [
            closeOnClickOutsideOfLogoSideRef,
            closeOnClickOutsideOfMainNavigationRef,
            closeOnClickOutsideOfLanguageSelectorMobileRef,
            closeOnClickOutsideOfLanguageSelectorDesktopRef,
        ],
        handleOnNavigationClose
    );

    useEffect(() => {
        const handleCloseOnEscape = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isNavigationOpen) {
                handleOnNavigationClose();
            }
        };

        document.addEventListener("keydown", handleCloseOnEscape);

        return () => {
            document.removeEventListener("keydown", handleCloseOnEscape);
        };
    }, [isNavigationOpen, handleOnNavigationClose]);

    useEffect(() => {
        console.log({ isAboveLarge, isAboveCustomBreakpoint });
        if (isNavigationOpen) {
            handleOnNavigationClose();
        }
        // we just want to close the navigation when the media query changes, if we included isNavigationOpen then it would close the navigation on every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAboveLarge, isAboveCustomBreakpoint]);

    useEffect(() => {
        if (isNavigationOpen) {
            setMobileLogoOpacity(0);
            const timer = window.setTimeout(() => setMobileLogoOpacity(1), 50);
            return () => window.clearTimeout(timer);
        } else {
            setMobileLogoOpacity(1);
        }
    }, [isNavigationOpen]);

    return (
        <Box>
            <AnimatePresence>
                {isNavigationOpen && (
                    <Backdrop
                        variants={animVariantsBackdrop}
                        initial="navigationCollapsed"
                        animate="navigationExpanded"
                        exit="navigationCollapsed"
                    />
                )}
            </AnimatePresence>

            {mainNavigationId && <LiveTicker mainNavigationId={mainNavigationId} />}

            <FocusLock disabled={!isNavigationOpen}>
                <RemoveScroll enabled={isNavigationOpen} forwardProps>
                    <div>
                        {/*Mobile*/}

                        <PorscheLogo
                            className="logo-mobile"
                            position={isNavigationOpen ? "fixed" : "absolute"}
                            zIndex="modal"
                            top={
                                hasLiveTicker
                                    ? "calc(var(--sizes-liveTickerHeight) + var(--space-4))"
                                    : 4
                            }
                            left={5}
                            transitionTimingFunction="var(--transition-property-common)"
                            transitionDuration="var(--transition-duration-moderate)"
                            transform={
                                isNavigationOpen && hasLiveTicker ? "translateY(-36px)" : "none"
                            }
                            opacity={mobileLogoOpacity}
                            display={{ base: "inline-block", l: "none" }}
                            onClick={handleOnNavigationClose}
                            forceLogoColorWhite={isNavigationOpen}
                        />

                        {highlightLink && !isNavigationOpen && (
                            <Box
                                display={{ base: "flex", l: "none" }}
                                position="fixed"
                                zIndex="modal"
                                top={2}
                                transitionTimingFunction="transform"
                                transitionDuration="var(--transition-duration-moderate)"
                                transform={{
                                    base: `translateY(${quickLinksMobileTransformY}px)`,
                                    l: "none",
                                }}
                                right={5}
                            >
                                <HighlightLink highlightLink={highlightLink} />
                            </Box>
                        )}

                        <AnimatePresence initial={false}>
                            {isNavigationOpen && (
                                <MotionBox
                                    position="fixed"
                                    zIndex="popover"
                                    top={4}
                                    right="var(--space-5)"
                                    display={{ base: "flex", l: "none" }}
                                    alignItems="center"
                                    gap={2}
                                    variants={animVariantsMobileToolbar}
                                    initial="navigationCollapsed"
                                    animate="navigationExpanded"
                                    exit="navigationCollapsed"
                                >
                                    {languageSelectorItemsCollection?.items && (
                                        <Box ref={closeOnClickOutsideOfLanguageSelectorMobileRef}>
                                            <LanguageSelector
                                                items={languageSelectorItemsCollection.items}
                                                onClose={handleOnNavigationClose}
                                            />
                                        </Box>
                                    )}
                                    <Button
                                        aria-controls={ariaId}
                                        icon="close"
                                        hideLabel
                                        aria-label="Close navigation"
                                        variant="ghost"
                                        theme="dark"
                                        compact
                                        onClick={handleOnNavigationClose}
                                    />
                                </MotionBox>
                            )}
                        </AnimatePresence>
                        {/*End Mobile*/}

                        <Box
                            position="fixed"
                            zIndex="modal"
                            width="full"
                            top={{ base: "auto", l: 0 }}
                            bottom={{ base: 4, l: "auto" }}
                            transitionTimingFunction="transform"
                            transitionDuration="var(--transition-duration-moderate)"
                            transform={{
                                base: "none",
                                l: `translateY(${quickLinksDesktopTransformY}px)`,
                            }}
                        >
                            <WrapperContainer
                                position="relative"
                                as={Grid}
                                gridTemplateColumns={{
                                    base: "repeat(2, 1fr)",
                                    l: "repeat(12, 1fr)",
                                }}
                                gap={0}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <GridItem
                                    as={Flex}
                                    display={{ base: "none", l: "flex" }}
                                    alignSelf="start"
                                    align="center"
                                    height="navQuickLinksHeight"
                                    ref={closeOnClickOutsideOfLogoSideRef}
                                    transform={{
                                        base: "none",
                                        l: `translateY(${desktopLogoTransformY}px)`,
                                    }}
                                >
                                    <PorscheLogo
                                        className="logo-desktop"
                                        display={{ base: "none", l: "block" }}
                                        onClick={handleOnNavigationClose}
                                        forceLogoColorWhite={isNavigationOpen}
                                    />
                                </GridItem>
                                <GridItem
                                    as={Flex}
                                    justifyContent="center"
                                    colSpan={{ base: 2, l: 9 }}
                                    colStart={{ base: 0, l: 2 }}
                                    ref={closeOnClickOutsideOfMainNavigationRef}
                                >
                                    <MotionBox
                                        variants={animVariantsWidth(
                                            isAboveLarge,
                                            isAboveCustomBreakpoint
                                        )}
                                        willChange="width,height"
                                        minWidth={{ base: "100%", l: "auto" }}
                                        animate={
                                            isNavigationOpen
                                                ? "navigationExpanded"
                                                : "navigationCollapsed"
                                        }
                                        sx={{
                                            contentVisibility: "auto",
                                        }}
                                    >
                                        <MotionBox
                                            id={ariaId}
                                            overflow="hidden"
                                            position="relative"
                                            display="flex"
                                            color="allWhite"
                                            backdropFilter={frostedGlassStyle.backdropFilter}
                                            backgroundColor="porscheBlackShaded"
                                            minHeight="navQuickLinksHeight"
                                            borderRadius="medium"
                                            variants={animVariantsHeight(
                                                isAboveLarge,
                                                targetHeightForExpandedNavigation
                                            )}
                                            willChange="width,height"
                                            initial="navigationCollapsed"
                                            sx={{
                                                contentVisibility: "auto",
                                            }}
                                            animate={
                                                isNavigationOpen
                                                    ? "navigationExpanded"
                                                    : "navigationCollapsed"
                                            }
                                        >
                                            {/*Quick Links bar*/}
                                            <AnimatePresence initial={false}>
                                                {!isNavigationOpen && (
                                                    <QuickLinks
                                                        layout
                                                        variants={animVariantsQuickLinks}
                                                        initial="quickLinksHidden"
                                                        animate="quickLinksVisible"
                                                        exit="quickLinksHidden"
                                                        position={{
                                                            base: "absolute",
                                                            l: "relative",
                                                        }}
                                                        top={{ base: "auto", l: 0 }}
                                                        bottom={{ base: 0, l: "auto" }}
                                                        left={0}
                                                    >
                                                        {seriesSectionLabel && (
                                                            <QuickLinkButton
                                                                aria-expanded={isNavigationOpen}
                                                                aria-controls={ariaId}
                                                                onClick={() =>
                                                                    handleOnNavigationOpen(
                                                                        tabIndices.series
                                                                    )
                                                                }
                                                            >
                                                                {seriesSectionLabel}
                                                            </QuickLinkButton>
                                                        )}
                                                        {carsSectionLabel && (
                                                            <QuickLinkButton
                                                                aria-expanded={isNavigationOpen}
                                                                aria-controls={ariaId}
                                                                onClick={() =>
                                                                    handleOnNavigationOpen(
                                                                        tabIndices.cars
                                                                    )
                                                                }
                                                            >
                                                                {carsSectionLabel}
                                                            </QuickLinkButton>
                                                        )}
                                                        {teamsSectionLabel && (
                                                            <QuickLinkButton
                                                                aria-expanded={isNavigationOpen}
                                                                aria-controls={ariaId}
                                                                onClick={() =>
                                                                    handleOnNavigationOpen(
                                                                        tabIndices.teams
                                                                    )
                                                                }
                                                            >
                                                                {teamsSectionLabel}
                                                            </QuickLinkButton>
                                                        )}
                                                        {eventsSectionLabel && (
                                                            <QuickLinkButton
                                                                aria-expanded={isNavigationOpen}
                                                                aria-controls={ariaId}
                                                                onClick={() =>
                                                                    handleOnNavigationOpen(
                                                                        tabIndices.events
                                                                    )
                                                                }
                                                            >
                                                                {eventsSectionLabel}
                                                            </QuickLinkButton>
                                                        )}
                                                        {journalPage && (
                                                            <QuickLink
                                                                item={journalPage}
                                                                display={{
                                                                    base: "none",
                                                                    l: "block",
                                                                }}
                                                            >
                                                                {journalPage.linkTitle ??
                                                                    journalPage.title}
                                                            </QuickLink>
                                                        )}
                                                        <QuickLinkIconButton
                                                            aria-label="open navigation"
                                                            aria-expanded={isNavigationOpen}
                                                            aria-controls={ariaId}
                                                            onClick={() =>
                                                                handleOnNavigationOpen(undefined)
                                                            }
                                                            sx={{
                                                                display: "flex",
                                                                [mediaQueryMinWidth.l]: {
                                                                    display: "none",
                                                                },
                                                            }}
                                                        />
                                                    </QuickLinks>
                                                )}
                                            </AnimatePresence>

                                            <MotionBox
                                                className="inner-content"
                                                position="absolute"
                                                width="100%"
                                                height={{
                                                    base: targetHeightForExpandedNavigation,
                                                    l: "640px",
                                                }}
                                                willChange="height, width"
                                                top={{ base: "auto", l: 0 }}
                                                bottom={{ base: 0, l: "auto" }}
                                                left="50%"
                                                transform="translateX(-50%)"
                                                transformOrigin="center top"
                                                zIndex={1}
                                                sx={{
                                                    contentVisibility: "auto",
                                                }}
                                                variants={animVariantsInnerContent}
                                                onAnimationComplete={
                                                    handleOnAnimationCompleteOnInnerContent
                                                }
                                            >
                                                <Tabs
                                                    index={tabIndex}
                                                    onChange={handleOnTabsChange}
                                                    height="100%"
                                                    display="flex"
                                                >
                                                    <Grid
                                                        templateColumns={{
                                                            base: "repeat(2, 1fr)",
                                                            l: "repeat(3, 1fr)",
                                                        }}
                                                        gap={6}
                                                        flex={1}
                                                    >
                                                        <MotionGridItem
                                                            layout
                                                            key={`tab-list-${isNavigationOpen}-${isTabListVisible}`}
                                                            className="tab-list"
                                                            colSpan={{ base: 2, l: 1 }}
                                                            as={TabList}
                                                            flexDirection="column"
                                                            alignItems="start"
                                                            px={6}
                                                            py={6}
                                                            position={{
                                                                base: "absolute",
                                                                l: "static",
                                                            }}
                                                            height={{
                                                                base: "100%",
                                                                l: "auto",
                                                            }}
                                                            overflowY="auto"
                                                            sx={{
                                                                scrollbarWidth: "none",
                                                                "&::webkit-scrollbar": {
                                                                    display: "none",
                                                                },
                                                            }}
                                                            pointerEvents={
                                                                isTabListVisible ? "auto" : "none"
                                                            }
                                                            variants={animVariantsTabList}
                                                            initial="tabListHiddenWithY"
                                                            animate={
                                                                isTabListVisible
                                                                    ? "tabListVisible"
                                                                    : "tabListHidden"
                                                            }
                                                            custom={{
                                                                delayChildren:
                                                                    !shouldAnimateInsideExpandedNavigation,
                                                            }}
                                                        >
                                                            <VStack
                                                                as={VStack}
                                                                align="start"
                                                                width="100%"
                                                                gap={2}
                                                            >
                                                                <MotionTab
                                                                    variants={animVariantsTab}
                                                                    onClick={handleOnTabOpen}
                                                                    highlight={
                                                                        tabIndex ===
                                                                        tabIndices.series
                                                                    }
                                                                >
                                                                    {seriesSectionLabel}
                                                                </MotionTab>

                                                                <MotionTab
                                                                    variants={animVariantsTab}
                                                                    onClick={handleOnTabOpen}
                                                                    highlight={
                                                                        tabIndex === tabIndices.cars
                                                                    }
                                                                >
                                                                    {carsSectionLabel}
                                                                </MotionTab>

                                                                <MotionTab
                                                                    variants={animVariantsTab}
                                                                    onClick={handleOnTabOpen}
                                                                    highlight={
                                                                        tabIndex ===
                                                                        tabIndices.teams
                                                                    }
                                                                >
                                                                    {teamsSectionLabel}
                                                                </MotionTab>

                                                                <MotionTab
                                                                    variants={animVariantsTab}
                                                                    onClick={handleOnTabOpen}
                                                                    highlight={
                                                                        tabIndex ===
                                                                        tabIndices.events
                                                                    }
                                                                >
                                                                    {eventsSectionLabel}
                                                                </MotionTab>

                                                                {journalPage?.__typename &&
                                                                    journalPage.sys.id && (
                                                                        <MotionSectionLink
                                                                            entryId={
                                                                                journalPage.sys.id
                                                                            }
                                                                            entryTypename={
                                                                                journalPage.__typename
                                                                            }
                                                                            href={getHrefForPageType(
                                                                                journalPage
                                                                            )}
                                                                            onClick={
                                                                                handleOnNavigationClose
                                                                            }
                                                                            variants={
                                                                                animVariantsTab
                                                                            }
                                                                        >
                                                                            {journalPage.linkTitle ??
                                                                                journalPage.title}
                                                                        </MotionSectionLink>
                                                                    )}
                                                            </VStack>
                                                        </MotionGridItem>

                                                        <MotionGridItem
                                                            layout
                                                            key="tab-panels"
                                                            className="tab-panels"
                                                            colSpan={2}
                                                            as={TabPanels}
                                                            position={{
                                                                base: "absolute",
                                                                l: "static",
                                                            }}
                                                            height={{
                                                                base: "100%",
                                                                l: "auto",
                                                            }}
                                                            overflowY="auto"
                                                            sx={{
                                                                scrollbarWidth: "none",
                                                                "&::webkit-scrollbar": {
                                                                    display: "none",
                                                                },
                                                            }}
                                                            pointerEvents={
                                                                isTabPanelsVisible ? "auto" : "none"
                                                            }
                                                            variants={animVariantsTabPanels}
                                                            initial="tabPanelsHidden"
                                                            animate={
                                                                isTabPanelsVisible
                                                                    ? "tabPanelsVisible"
                                                                    : "tabPanelsHidden"
                                                            }
                                                        >
                                                            <MotionTabPanel
                                                                key="tab-panel-series"
                                                                variants={animVariantsTabPanel}
                                                                initial="tabPanelHidden"
                                                                animate={
                                                                    tabIndex === tabIndices.series
                                                                        ? "tabPanelVisible"
                                                                        : "tabPanelHidden"
                                                                }
                                                                custom={{
                                                                    delay: !shouldAnimateInsideExpandedNavigation,
                                                                    isAboveLarge,
                                                                }}
                                                            >
                                                                <MobileTabClose
                                                                    onClick={handleOnTabClose}
                                                                >
                                                                    {seriesSectionLabel}
                                                                </MobileTabClose>
                                                                <TabScrollBox>
                                                                    <SectionAccordion
                                                                        index={seriesAccordionIndex}
                                                                        onChange={(index) =>
                                                                            setSeriesAccordionIndex(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {seriesSectionCollection?.items.map(
                                                                            (
                                                                                seriesSectionCollectionItem,
                                                                                index
                                                                            ) => (
                                                                                <SectionAccordionItem
                                                                                    key={`${seriesSectionCollectionItem?.sys.id}-${index}`}
                                                                                >
                                                                                    {({
                                                                                        isExpanded,
                                                                                    }) => (
                                                                                        <>
                                                                                            <SectionAccordionButton
                                                                                                isExpanded={
                                                                                                    isExpanded
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    seriesSectionCollectionItem?.title
                                                                                                }
                                                                                            </SectionAccordionButton>
                                                                                            <SectionAccordionPanel>
                                                                                                <MotionSectionGrid
                                                                                                    initial="hidden"
                                                                                                    animate={
                                                                                                        tabIndex ===
                                                                                                            tabIndices.series &&
                                                                                                        isExpanded
                                                                                                            ? "visible"
                                                                                                            : "hidden"
                                                                                                    }
                                                                                                    variants={
                                                                                                        animVariantsSectionGrid
                                                                                                    }
                                                                                                    custom={{
                                                                                                        delayChildren:
                                                                                                            !shouldAnimateInsideExpandedNavigation,
                                                                                                        isAboveLarge,
                                                                                                    }}
                                                                                                >
                                                                                                    {seriesSectionCollectionItem?.itemsCollection?.items.map(
                                                                                                        (
                                                                                                            seriesSectionCollectionItemItem,
                                                                                                            index
                                                                                                        ) =>
                                                                                                            seriesSectionCollectionItemItem && (
                                                                                                                <MotionSectionGridItem
                                                                                                                    key={`${seriesSectionCollectionItemItem?.sys.id}-${index}`}
                                                                                                                    variants={
                                                                                                                        animVariantsSectionGridItem
                                                                                                                    }
                                                                                                                >
                                                                                                                    <SectionLinkCard
                                                                                                                        {...seriesSectionCollectionItemItem}
                                                                                                                        onClick={
                                                                                                                            handleOnNavigationClose
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <SeriesMarquee
                                                                                                                            theme={
                                                                                                                                seriesSectionCollectionItemItem.theme
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {String(
                                                                                                                                seriesSectionCollectionItemItem.label
                                                                                                                            )}
                                                                                                                        </SeriesMarquee>
                                                                                                                    </SectionLinkCard>
                                                                                                                </MotionSectionGridItem>
                                                                                                            )
                                                                                                    )}
                                                                                                </MotionSectionGrid>
                                                                                            </SectionAccordionPanel>
                                                                                        </>
                                                                                    )}
                                                                                </SectionAccordionItem>
                                                                            )
                                                                        )}
                                                                    </SectionAccordion>
                                                                </TabScrollBox>
                                                            </MotionTabPanel>

                                                            <MotionTabPanel
                                                                key="tab-panel-cars"
                                                                variants={animVariantsTabPanel}
                                                                initial="tabPanelHidden"
                                                                animate={
                                                                    tabIndex === tabIndices.cars
                                                                        ? "tabPanelVisible"
                                                                        : "tabPanelHidden"
                                                                }
                                                                custom={{
                                                                    delay: !shouldAnimateInsideExpandedNavigation,
                                                                    isAboveLarge,
                                                                }}
                                                            >
                                                                <MobileTabClose
                                                                    onClick={handleOnTabClose}
                                                                >
                                                                    {carsSectionLabel}
                                                                </MobileTabClose>
                                                                <TabScrollBox>
                                                                    <MotionSectionGrid
                                                                        initial="hidden"
                                                                        animate={
                                                                            tabIndex ===
                                                                            tabIndices.cars
                                                                                ? "visible"
                                                                                : "hidden"
                                                                        }
                                                                        variants={
                                                                            animVariantsSectionGrid
                                                                        }
                                                                        custom={{
                                                                            delayChildren:
                                                                                !shouldAnimateInsideExpandedNavigation,
                                                                            isAboveLarge,
                                                                        }}
                                                                    >
                                                                        {carsSectionCollection?.items.map(
                                                                            (
                                                                                carsSectionCollectionItem,
                                                                                index
                                                                            ) =>
                                                                                carsSectionCollectionItem && (
                                                                                    <MotionSectionGridItem
                                                                                        key={`${carsSectionCollectionItem?.sys.id}-${index}`}
                                                                                        variants={
                                                                                            animVariantsSectionGridItem
                                                                                        }
                                                                                    >
                                                                                        <SectionLinkCard
                                                                                            {...carsSectionCollectionItem}
                                                                                            onClick={
                                                                                                handleOnNavigationClose
                                                                                            }
                                                                                        >
                                                                                            <SectionImage
                                                                                                cloudinaryAsset={
                                                                                                    carsSectionCollectionItem.image
                                                                                                }
                                                                                                gravity="center"
                                                                                            />
                                                                                        </SectionLinkCard>
                                                                                    </MotionSectionGridItem>
                                                                                )
                                                                        )}
                                                                    </MotionSectionGrid>
                                                                </TabScrollBox>
                                                            </MotionTabPanel>

                                                            <MotionTabPanel
                                                                key="tab-panel-teams"
                                                                variants={animVariantsTabPanel}
                                                                initial="tabPanelHidden"
                                                                animate={
                                                                    tabIndex === tabIndices.teams
                                                                        ? "tabPanelVisible"
                                                                        : "tabPanelHidden"
                                                                }
                                                                custom={{
                                                                    delay: !shouldAnimateInsideExpandedNavigation,
                                                                    isAboveLarge,
                                                                }}
                                                            >
                                                                <MobileTabClose
                                                                    onClick={handleOnTabClose}
                                                                >
                                                                    {teamsSectionLabel}
                                                                </MobileTabClose>
                                                                <TabScrollBox>
                                                                    <MotionSectionGrid
                                                                        initial="visible"
                                                                        animate={
                                                                            tabIndex ===
                                                                            tabIndices.teams
                                                                                ? "visible"
                                                                                : "hidden"
                                                                        }
                                                                        variants={
                                                                            animVariantsSectionGrid
                                                                        }
                                                                        custom={{
                                                                            delayChildren:
                                                                                !shouldAnimateInsideExpandedNavigation,
                                                                            isAboveLarge,
                                                                        }}
                                                                    >
                                                                        {teamsSectionCollection?.items.map(
                                                                            (
                                                                                teamsSectionCollectionItem,
                                                                                index
                                                                            ) =>
                                                                                teamsSectionCollectionItem && (
                                                                                    <MotionSectionGridItem
                                                                                        key={`${teamsSectionCollectionItem?.sys.id}-${index}`}
                                                                                        variants={
                                                                                            animVariantsSectionGridItem
                                                                                        }
                                                                                    >
                                                                                        <SectionLinkCard
                                                                                            {...teamsSectionCollectionItem}
                                                                                            onClick={
                                                                                                handleOnNavigationClose
                                                                                            }
                                                                                        >
                                                                                            <SectionImage
                                                                                                wrapperProps={{
                                                                                                    backgroundColor:
                                                                                                        "rgba(255,255,255,0.1)",
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    alignItems:
                                                                                                        "center",
                                                                                                }}
                                                                                                cloudinaryAsset={
                                                                                                    teamsSectionCollectionItem.image
                                                                                                }
                                                                                                gravity="center"
                                                                                                motionWrapperProps={{
                                                                                                    maxHeight:
                                                                                                        "60%",
                                                                                                }}
                                                                                            />
                                                                                        </SectionLinkCard>
                                                                                    </MotionSectionGridItem>
                                                                                )
                                                                        )}
                                                                    </MotionSectionGrid>
                                                                </TabScrollBox>
                                                            </MotionTabPanel>

                                                            <MotionTabPanel
                                                                key="tab-panel-events"
                                                                variants={animVariantsTabPanel}
                                                                initial="tabPanelHidden"
                                                                animate={
                                                                    tabIndex === tabIndices.events
                                                                        ? "tabPanelVisible"
                                                                        : "tabPanelHidden"
                                                                }
                                                                custom={{
                                                                    delay: !shouldAnimateInsideExpandedNavigation,
                                                                    isAboveLarge,
                                                                }}
                                                            >
                                                                <MobileTabClose
                                                                    onClick={handleOnTabClose}
                                                                >
                                                                    {eventsSectionLabel}
                                                                </MobileTabClose>
                                                                <TabScrollBox>
                                                                    <SectionAccordion
                                                                        index={eventsAccordionIndex}
                                                                        onChange={(index) =>
                                                                            setEventsAccordionIndex(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        {eventsSectionCollection?.items.map(
                                                                            (
                                                                                eventsSectionCollectionItem,
                                                                                index
                                                                            ) => (
                                                                                <SectionAccordionItem
                                                                                    key={`${eventsSectionCollectionItem?.sys.id}-${index}`}
                                                                                >
                                                                                    {({
                                                                                        isExpanded,
                                                                                    }) => (
                                                                                        <>
                                                                                            <SectionAccordionButton
                                                                                                isExpanded={
                                                                                                    isExpanded
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    eventsSectionCollectionItem?.title
                                                                                                }
                                                                                            </SectionAccordionButton>
                                                                                            <SectionAccordionPanel>
                                                                                                <MotionSectionGrid
                                                                                                    initial="hidden"
                                                                                                    animate={
                                                                                                        tabIndex ===
                                                                                                            tabIndices.events &&
                                                                                                        isExpanded
                                                                                                            ? "visible"
                                                                                                            : "hidden"
                                                                                                    }
                                                                                                    variants={
                                                                                                        animVariantsSectionGrid
                                                                                                    }
                                                                                                    custom={{
                                                                                                        delayChildren:
                                                                                                            !shouldAnimateInsideExpandedNavigation,
                                                                                                        isAboveLarge,
                                                                                                    }}
                                                                                                >
                                                                                                    {eventsSectionCollectionItem?.itemsCollection?.items.map(
                                                                                                        (
                                                                                                            eventsSectionCollectionItemItem,
                                                                                                            index
                                                                                                        ) =>
                                                                                                            eventsSectionCollectionItemItem && (
                                                                                                                <MotionSectionGridItem
                                                                                                                    key={`${eventsSectionCollectionItemItem?.sys.id}-${index}`}
                                                                                                                    variants={
                                                                                                                        animVariantsSectionGridItem
                                                                                                                    }
                                                                                                                >
                                                                                                                    <SectionLinkCard
                                                                                                                        {...eventsSectionCollectionItemItem}
                                                                                                                        onClick={
                                                                                                                            handleOnNavigationClose
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <SectionImage
                                                                                                                            cloudinaryAsset={
                                                                                                                                eventsSectionCollectionItemItem.image
                                                                                                                            }
                                                                                                                            objectFit="cover"
                                                                                                                        />
                                                                                                                    </SectionLinkCard>
                                                                                                                </MotionSectionGridItem>
                                                                                                            )
                                                                                                    )}
                                                                                                </MotionSectionGrid>
                                                                                            </SectionAccordionPanel>
                                                                                        </>
                                                                                    )}
                                                                                </SectionAccordionItem>
                                                                            )
                                                                        )}
                                                                    </SectionAccordion>
                                                                </TabScrollBox>
                                                            </MotionTabPanel>
                                                        </MotionGridItem>
                                                    </Grid>
                                                    <AnimatePresence>
                                                        {isNavigationOpen && (
                                                            <MotionBox
                                                                className="close-button"
                                                                position="absolute"
                                                                top="var(--space-2)"
                                                                right="var(--space-2)"
                                                                sx={{
                                                                    display: "none",
                                                                    [mediaQueryMinWidth.l]: {
                                                                        display: "flex",
                                                                    },
                                                                }}
                                                            >
                                                                <Button
                                                                    aria-controls={ariaId}
                                                                    onClick={
                                                                        handleOnNavigationClose
                                                                    }
                                                                    icon="close"
                                                                    hideLabel
                                                                    aria-label="Close navigation"
                                                                    variant="ghost"
                                                                    theme="dark"
                                                                    compact
                                                                />
                                                            </MotionBox>
                                                        )}
                                                    </AnimatePresence>
                                                </Tabs>
                                            </MotionBox>
                                        </MotionBox>
                                    </MotionBox>
                                </GridItem>
                                <GridItem
                                    as={HStack}
                                    justifyContent="end"
                                    gap={3}
                                    position="absolute"
                                    right={10}
                                    top={0}
                                    display={{ base: "none", l: "flex" }}
                                >
                                    {languageSelectorItemsCollection?.items && (
                                        <Box ref={closeOnClickOutsideOfLanguageSelectorDesktopRef}>
                                            <LanguageSelector
                                                items={languageSelectorItemsCollection.items}
                                                onClose={handleOnNavigationClose}
                                            />
                                        </Box>
                                    )}
                                    {highlightLink && (
                                        <HighlightLink highlightLink={highlightLink} />
                                    )}
                                </GridItem>
                            </WrapperContainer>
                        </Box>
                    </div>
                </RemoveScroll>
            </FocusLock>
        </Box>
    );
};
