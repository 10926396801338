import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuickLinks_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type ModuleQuickLinksFieldsFragment = {
    __typename?: "ModuleQuickLinks";
    title?: string | null;
    description?: string | null;
    linksCollection?: {
        __typename?: "ModuleQuickLinksLinksCollection";
        items: Array<
            | ({
                  __typename?: "ExternalLink";
                  label?: string | null;
                  url?: string | null;
              } & ComponentReferenceFields_ExternalLink_Fragment)
            | ({
                  __typename?: "PageArticle";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageArticle_Fragment)
            | ({
                  __typename?: "PageBasic";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageBasic_Fragment)
            | ({
                  __typename?: "PageCar";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageCar_Fragment)
            | ({
                  __typename?: "PageCategory";
                  title?: string | null;
                  linkTitle?: string | null;
                  mainCategory?: string | null;
              } & ComponentReferenceFields_PageCategory_Fragment)
            | ({
                  __typename?: "PageDriver";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageDriver_Fragment)
            | ({
                  __typename?: "PageHomepage";
                  title?: string | null;
                  linkTitle?: string | null;
              } & ComponentReferenceFields_PageHomepage_Fragment)
            | ({
                  __typename?: "PageRaceEvent";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageRaceEvent_Fragment)
            | ({
                  __typename?: "PageRaceSeries";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageRaceSeries_Fragment)
            | ({
                  __typename?: "PageSearch";
                  title?: string | null;
                  linkTitle?: string | null;
              } & ComponentReferenceFields_PageSearch_Fragment)
            | ({
                  __typename?: "PageTeam";
                  title?: string | null;
                  linkTitle?: string | null;
                  slug?: string | null;
              } & ComponentReferenceFields_PageTeam_Fragment)
            | null
        >;
    } | null;
} & ComponentReferenceFields_ModuleQuickLinks_Fragment;

export type ModuleQuickLinksQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview: Types.Scalars["Boolean"]["input"];
    id: Types.Scalars["String"]["input"];
}>;

export type ModuleQuickLinksQuery = {
    __typename?: "Query";
    moduleQuickLinks?:
        | ({ __typename?: "ModuleQuickLinks" } & ModuleQuickLinksFieldsFragment)
        | null;
};

export const ModuleQuickLinksFieldsFragmentDoc = `
    fragment ModuleQuickLinksFields on ModuleQuickLinks {
  ...ComponentReferenceFields
  title
  description
  linksCollection(limit: 12) {
    items {
      ... on PageArticle {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on PageBasic {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on PageCar {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on PageCategory {
        ...ComponentReferenceFields
        title
        linkTitle
        mainCategory
      }
      ... on PageDriver {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on PageHomepage {
        ...ComponentReferenceFields
        title
        linkTitle
      }
      ... on PageRaceSeries {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on PageRaceEvent {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on PageSearch {
        ...ComponentReferenceFields
        title
        linkTitle
      }
      ... on PageTeam {
        ...ComponentReferenceFields
        title
        linkTitle
        slug
      }
      ... on ExternalLink {
        ...ComponentReferenceFields
        label
        url
      }
    }
  }
}
    `;
export const ModuleQuickLinksDocument = `
    query ModuleQuickLinks($locale: String!, $preview: Boolean!, $id: String!) {
  moduleQuickLinks(id: $id, locale: $locale, preview: $preview) {
    ...ModuleQuickLinksFields
  }
}
    ${ModuleQuickLinksFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}`;

export const useModuleQuickLinksQuery = <TData = ModuleQuickLinksQuery, TError = unknown>(
    variables: ModuleQuickLinksQueryVariables,
    options?: Omit<UseQueryOptions<ModuleQuickLinksQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<ModuleQuickLinksQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<ModuleQuickLinksQuery, TError, TData>({
        queryKey: ["ModuleQuickLinks", variables],
        queryFn: customFetcher<ModuleQuickLinksQuery, ModuleQuickLinksQueryVariables>(
            ModuleQuickLinksDocument,
            variables
        ),
        ...options,
    });
};

useModuleQuickLinksQuery.getKey = (variables: ModuleQuickLinksQueryVariables) => [
    "ModuleQuickLinks",
    variables,
];

useModuleQuickLinksQuery.fetcher = (
    variables: ModuleQuickLinksQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<ModuleQuickLinksQuery, ModuleQuickLinksQueryVariables>(
        ModuleQuickLinksDocument,
        variables,
        options
    );
