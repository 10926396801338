import type { ReactNode } from "react";
import type { Options, RenderNode } from "@contentful/rich-text-react-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Block, Document, Inline } from "@contentful/rich-text-types";
import { renderMark } from "@/lib/contentful/rich-text-renderer/render-mark";
import {
    renderNode,
    renderTableTextWithLineBreaks,
} from "@/lib/contentful/rich-text-renderer/render-node";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { ModuleRenderer } from "@/lib/contentful/modules/ModuleRenderer";
import { Box, LinkPureExternal, NextLink, Tag, Td, Th } from "@project/ui";
import type { BoxProps } from "@project/ui";
import { useRouter } from "next/router";
import { getDictionaryItem } from "@/common/enums/dictionary";
import type { LinkWrapperProps } from "@/components/link-wrapper";
import { LinkWrapper } from "@/components/link-wrapper";

export type GenericRichText = {
    __typename?: string;
    json: Document;
    links: {
        entries: {
            /**
             * We are using any here because we don't have a common type for all the entries in the system.
             */
            /* eslint-disable @typescript-eslint/no-explicit-any */
            block?: any;
            inline?: any;
            hyperlink?: any;
            /* eslint-enable @typescript-eslint/no-explicit-any */
        };
    };
};

export const getOptions = (data: GenericRichText): Options => {
    const populateMap = <T extends { sys: { id: string } }>(
        entries: T[] | undefined
    ): Map<string, T> => {
        /**
         * filter out any undefined entries, ideally we would somehow show this as a warning in the ui, for now we log it
         */
        const nonUndefinedEntries = [...(entries ? entries.filter((entry) => !!entry) : [])];
        if (nonUndefinedEntries.length < (entries?.length ?? 0)) {
            // eslint-disable-next-line no-console
            console.warn(
                "Some entries in the rich text field were undefined, this is commonly because of a content model being deleted or renamed, while being used in a rich text field."
            );
        }

        return new Map(
            nonUndefinedEntries.map((entry) => {
                return [entry.sys.id, entry];
            })
        );
    };

    const linkedEmbeddedBlockEntries = populateMap(data.links?.entries?.block);
    const linkedEmbeddedInlineEntries = populateMap(data.links?.entries?.inline);
    const linkedHyperlinkEntries = populateMap(data.links?.entries?.hyperlink);

    const renderNodeOptions: RenderNode = {
        ...renderNode,
        [BLOCKS.EMBEDDED_ENTRY]: getNodeRenderer(linkedEmbeddedBlockEntries, renderEmbeddedEntry),
        [INLINES.EMBEDDED_ENTRY]: getNodeRenderer(linkedEmbeddedInlineEntries, renderInlineEntry),
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
            const item = linkedHyperlinkEntries.get(
                node.data.target.sys.id
            ) as LinkWrapperProps["item"];

            return (
                <LinkWrapper item={item} theme="light" icon="none" underline={true}>
                    {children}
                </LinkWrapper>
            );
        },
        [BLOCKS.TABLE_HEADER_CELL]: (node) => {
            const transformedChildren = documentToReactComponents(node as unknown as Document, {
                renderMark,
                renderNode: getTableCellNodeRenderer(
                    linkedHyperlinkEntries,
                    BLOCKS.TABLE_HEADER_CELL
                ),
            });

            return <Th>{transformedChildren}</Th>;
        },
        [BLOCKS.TABLE_CELL]: (node) => {
            const transformedChildren = documentToReactComponents(node as unknown as Document, {
                renderMark,
                renderNode: getTableCellNodeRenderer(linkedHyperlinkEntries, BLOCKS.TABLE_CELL),
            });

            return <Td>{transformedChildren}</Td>;
        },
    };

    return {
        renderMark,
        renderNode: renderNodeOptions,
        preserveWhitespace: false,
    };
};

function getNodeRenderer<T>(
    entriesMap: Map<string, T>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    renderFunction: (entry: T, ...args: any[]) => JSX.Element | null,
    ...args: any[]
) {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    return (node: Block | Inline, children?: ReactNode) => {
        const id = node.data.target.sys.id;
        const entry = entriesMap.get(id);
        if (entry) {
            return renderFunction(entry, ...args, children);
        }
        return null;
    };
}

const WiderThanContainer = ({ children, ...props }: BoxProps) => (
    <Box className="wider-than-container" mx={{ base: -5, md: -10, l: "-27%" }} {...props}>
        {children}
    </Box>
);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function renderEmbeddedEntry(entry: any) {
    if (entry.__typename === "ModuleQuote") {
        return (
            <Box className="module-quoute-wrapping-div" mx={{ base: -5, l: -10 }} height={"100%"}>
                <ModuleRenderer componentProps={{ ...entry, isEmbedded: true }} />
            </Box>
        );
    }
    if (entry.__typename === "ModuleImage") {
        return (
            <WiderThanContainer>
                <ModuleRenderer componentProps={entry} />
            </WiderThanContainer>
        );
    }
    if (entry.__typename === "ModuleVideo") {
        return (
            <WiderThanContainer>
                <ModuleRenderer componentProps={entry} />
            </WiderThanContainer>
        );
    }
    if (entry.__typename === "ModuleAudioPlayer") {
        return (
            <Box py={{ base: 10, md: 20 }} className="module-audio-player-wrapping-div">
                <ModuleRenderer
                    componentProps={{
                        ...entry,
                        isEmbedded: true,
                    }}
                />
            </Box>
        );
    }
    if (entry.__typename === "ModuleSpacer") {
        return (
            <Box className="module-spacer-wrapping-div">
                <ModuleRenderer componentProps={entry} />
            </Box>
        );
    }
    if (entry.__typename === "ModuleQuickLinks") {
        return <ModuleRenderer componentProps={{ ...entry, isEmbedded: true }} />;
    }
    if (entry.__typename === "ModuleGallery" || entry.__typename === "ModuleAccordion") {
        return (
            <WiderThanContainer>
                <ModuleRenderer componentProps={{ ...entry, isEmbedded: true }} />
            </WiderThanContainer>
        );
    }

    return (
        <Box my={{ base: 10, md: 20 }} className="module-generic-wrapping-div">
            <ModuleRenderer componentProps={entry} />
        </Box>
    );
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function renderInlineEntry(entry: any) {
    if (entry?.__typename === "Microcopy") {
        return entry.value;
    }

    if (entry?.__typename === "ContentTag") {
        return (
            <NextLink
                href={{
                    pathname: "/search",
                    query: { tags: [entry.tagKey] },
                }}
            >
                <Tag color="background-surface">{entry.label}</Tag>
            </NextLink>
        );
    }

    return null;
}

function getTableCellNodeRenderer<T>(
    entriesMap: Map<string, T>,
    tableKey: BLOCKS.TABLE_HEADER_CELL | BLOCKS.TABLE_CELL
) {
    return {
        [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) =>
            renderTableTextWithLineBreaks(children),
        [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
            const { locale } = useRouter();
            const href = node.data.uri;

            return (
                <LinkPureExternal
                    target="_blank"
                    href={href}
                    theme="light"
                    icon="none"
                    underline={true}
                    title={getDictionaryItem("blankHrefTitle", locale)}
                >
                    {children}
                </LinkPureExternal>
            );
        },
        [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
            const item = entriesMap.get(node.data.target.sys.id) as LinkWrapperProps["item"];

            return (
                <LinkWrapper item={item} theme="light" icon="none" underline={true}>
                    {children}
                </LinkWrapper>
            );
        },
        [tableKey]: (_node: Block | Inline, children: ReactNode) => children,
    };
}
