import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuickLinks_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ContentTagFieldsFragment } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { TeamFieldsFragment } from "../../team/__generated/team.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import { ContentTagFieldsFragmentDoc } from "../../../../lib/contentful/content-tag/__generated/content-tag.contentful.generated";
import { TeamFieldsFragmentDoc } from "../../team/__generated/team.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type PageTeamLinkToFieldsFragment = {
    __typename?: "PageTeam";
    title?: string | null;
    linkTitle?: string | null;
    slug?: string | null;
    tagsCollection?: {
        __typename?: "PageTeamTagsCollection";
        items: Array<({ __typename?: "ContentTag" } & ContentTagFieldsFragment) | null>;
    } | null;
    team?: ({ __typename?: "Team" } & TeamFieldsFragment) | null;
} & ComponentReferenceFields_PageTeam_Fragment;

export type PageTeamFieldsFragment = {
    __typename?: "PageTeam";
    introductionCaption?: string | null;
    introduction?: string | null;
    introHeading?: string | null;
    introColumn1?: string | null;
    introColumn2?: string | null;
    seoMetaDescription?: string | null;
    robotFollow?: string | null;
    robotIndex?: string | null;
    team?: ({ __typename?: "Team" } & TeamFieldsFragment) | null;
    modulesCollection?: {
        __typename: "PageTeamModulesCollection";
        items: Array<
            | { __typename: "ModuleAccordion"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarTechSpecs"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarousel"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCircuit"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleDriverList"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleGallery"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleImage"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleMediaFeature"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModulePageLinkTile"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleQuickLinks"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleQuote"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleRichText"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSideBySide"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSpacer"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleTable"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleVideo"; sys: { __typename?: "Sys"; id: string } }
            | null
        >;
    } | null;
    partnerSet?: ({ __typename?: "PartnerSet" } & PartnerSetFieldsFragment) | null;
} & PageTeamLinkToFieldsFragment;

export type PageTeamCollectionQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    slug: Types.Scalars["String"]["input"];
}>;

export type PageTeamCollectionQuery = {
    __typename?: "Query";
    pageTeamCollection?: {
        __typename?: "PageTeamCollection";
        items: Array<({ __typename?: "PageTeam" } & PageTeamFieldsFragment) | null>;
    } | null;
};

export type PageTeamQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    id: Types.Scalars["String"]["input"];
}>;

export type PageTeamQuery = {
    __typename?: "Query";
    page?: ({ __typename?: "PageTeam" } & PageTeamFieldsFragment) | null;
};

export const PageTeamLinkToFieldsFragmentDoc = `
    fragment PageTeamLinkToFields on PageTeam {
  ...ComponentReferenceFields
  title
  linkTitle
  slug
  tagsCollection {
    items {
      ...ContentTagFields
    }
  }
  team {
    ...TeamFields
  }
}
    `;
export const PageTeamFieldsFragmentDoc = `
    fragment PageTeamFields on PageTeam {
  ...PageTeamLinkToFields
  team {
    ...TeamFields
  }
  introductionCaption
  introduction
  introHeading
  introColumn1
  introColumn2
  modulesCollection(limit: 20) {
    __typename
    items {
      __typename
      ... on Entry {
        sys {
          id
        }
      }
    }
  }
  partnerSet {
    ...PartnerSetFields
  }
  seoMetaDescription
  robotFollow
  robotIndex
}
    `;
export const PageTeamCollectionDocument = `
    query PageTeamCollection($locale: String!, $preview: Boolean, $slug: String!) {
  pageTeamCollection(
    limit: 1
    locale: $locale
    preview: $preview
    where: {slug: $slug}
  ) {
    items {
      ...PageTeamFields
    }
  }
}
    ${PageTeamFieldsFragmentDoc}
${PageTeamLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${TeamFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageTeamCollectionQuery = <TData = PageTeamCollectionQuery, TError = unknown>(
    variables: PageTeamCollectionQueryVariables,
    options?: Omit<UseQueryOptions<PageTeamCollectionQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageTeamCollectionQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageTeamCollectionQuery, TError, TData>({
        queryKey: ["PageTeamCollection", variables],
        queryFn: customFetcher<PageTeamCollectionQuery, PageTeamCollectionQueryVariables>(
            PageTeamCollectionDocument,
            variables
        ),
        ...options,
    });
};

usePageTeamCollectionQuery.getKey = (variables: PageTeamCollectionQueryVariables) => [
    "PageTeamCollection",
    variables,
];

usePageTeamCollectionQuery.fetcher = (
    variables: PageTeamCollectionQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageTeamCollectionQuery, PageTeamCollectionQueryVariables>(
        PageTeamCollectionDocument,
        variables,
        options
    );

export const PageTeamDocument = `
    query PageTeam($locale: String!, $preview: Boolean, $id: String!) {
  page: pageTeam(id: $id, locale: $locale, preview: $preview) {
    ...PageTeamFields
  }
}
    ${PageTeamFieldsFragmentDoc}
${PageTeamLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${ContentTagFieldsFragmentDoc}
${TeamFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageTeamQuery = <TData = PageTeamQuery, TError = unknown>(
    variables: PageTeamQueryVariables,
    options?: Omit<UseQueryOptions<PageTeamQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageTeamQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageTeamQuery, TError, TData>({
        queryKey: ["PageTeam", variables],
        queryFn: customFetcher<PageTeamQuery, PageTeamQueryVariables>(PageTeamDocument, variables),
        ...options,
    });
};

usePageTeamQuery.getKey = (variables: PageTeamQueryVariables) => ["PageTeam", variables];

usePageTeamQuery.fetcher = (variables: PageTeamQueryVariables, options?: RequestInit["headers"]) =>
    customFetcher<PageTeamQuery, PageTeamQueryVariables>(PageTeamDocument, variables, options);
