import * as Types from "../../../../lib/contentful/__generated/graphql.types";

import {
    ComponentReferenceFields_Car_Fragment,
    ComponentReferenceFields_ContentTag_Fragment,
    ComponentReferenceFields_Driver_Fragment,
    ComponentReferenceFields_ExternalLink_Fragment,
    ComponentReferenceFields_Footer_Fragment,
    ComponentReferenceFields_LanguageSelectorItem_Fragment,
    ComponentReferenceFields_MainNavigation_Fragment,
    ComponentReferenceFields_Microcopy_Fragment,
    ComponentReferenceFields_MicrocopySet_Fragment,
    ComponentReferenceFields_ModalLink_Fragment,
    ComponentReferenceFields_ModuleAccordion_Fragment,
    ComponentReferenceFields_ModuleAudioPlayer_Fragment,
    ComponentReferenceFields_ModuleCarTechSpecs_Fragment,
    ComponentReferenceFields_ModuleCarousel_Fragment,
    ComponentReferenceFields_ModuleCircuit_Fragment,
    ComponentReferenceFields_ModuleDriverList_Fragment,
    ComponentReferenceFields_ModuleGallery_Fragment,
    ComponentReferenceFields_ModuleIframe_Fragment,
    ComponentReferenceFields_ModuleImage_Fragment,
    ComponentReferenceFields_ModuleListenToTheEngine_Fragment,
    ComponentReferenceFields_ModuleMediaFeature_Fragment,
    ComponentReferenceFields_ModulePageLinkTile_Fragment,
    ComponentReferenceFields_ModuleQuickLinks_Fragment,
    ComponentReferenceFields_ModuleQuote_Fragment,
    ComponentReferenceFields_ModuleRichText_Fragment,
    ComponentReferenceFields_ModuleSideBySide_Fragment,
    ComponentReferenceFields_ModuleSpacer_Fragment,
    ComponentReferenceFields_ModuleSplitLayout_Fragment,
    ComponentReferenceFields_ModuleTable_Fragment,
    ComponentReferenceFields_ModuleVideo_Fragment,
    ComponentReferenceFields_Navigation_Fragment,
    ComponentReferenceFields_PageArticle_Fragment,
    ComponentReferenceFields_PageBasic_Fragment,
    ComponentReferenceFields_PageCar_Fragment,
    ComponentReferenceFields_PageCategory_Fragment,
    ComponentReferenceFields_PageDriver_Fragment,
    ComponentReferenceFields_PageHomepage_Fragment,
    ComponentReferenceFields_PageRaceEvent_Fragment,
    ComponentReferenceFields_PageRaceSeries_Fragment,
    ComponentReferenceFields_PageSearch_Fragment,
    ComponentReferenceFields_PageTeam_Fragment,
    ComponentReferenceFields_PartAccordionItem_Fragment,
    ComponentReferenceFields_PartCircuitHotspot_Fragment,
    ComponentReferenceFields_PartMainNavigationAccordion_Fragment,
    ComponentReferenceFields_PartMainNavigationItem_Fragment,
    ComponentReferenceFields_Partner_Fragment,
    ComponentReferenceFields_PartnerSet_Fragment,
    ComponentReferenceFields_SeoMetadata_Fragment,
    ComponentReferenceFields_SiteSettings_Fragment,
    ComponentReferenceFields_Team_Fragment,
} from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import {
    PartnerSetFieldsFragment,
    PartnerFieldsFragment,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { ComponentReferenceFieldsFragmentDoc } from "../../../../lib/contentful/__generated/component-map.contentful.generated";
import {
    PartnerSetFieldsFragmentDoc,
    PartnerFieldsFragmentDoc,
} from "../../partner-set/__generated/partner-set.contentful.generated";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { customFetcher } from "@/lib/contentful/fetch-config";
export type PageSearchLinkToFieldsFragment = {
    __typename?: "PageSearch";
    title?: string | null;
    linkTitle?: string | null;
} & ComponentReferenceFields_PageSearch_Fragment;

export type PageSearchFieldsFragment = {
    __typename?: "PageSearch";
    seoMetaDescription?: string | null;
    robotFollow?: string | null;
    robotIndex?: string | null;
    modulesCollection?: {
        __typename: "PageSearchModulesCollection";
        items: Array<
            | { __typename: "ModuleAccordion"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarTechSpecs"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCarousel"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleCircuit"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleDriverList"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleGallery"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleImage"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleMediaFeature"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleQuote"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleRichText"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleSpacer"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleTable"; sys: { __typename?: "Sys"; id: string } }
            | { __typename: "ModuleVideo"; sys: { __typename?: "Sys"; id: string } }
            | null
        >;
    } | null;
    partnerSet?: ({ __typename?: "PartnerSet" } & PartnerSetFieldsFragment) | null;
} & PageSearchLinkToFieldsFragment;

export type PageSearchCollectionQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type PageSearchCollectionQuery = {
    __typename?: "Query";
    pageSearchCollection?: {
        __typename?: "PageSearchCollection";
        items: Array<({ __typename?: "PageSearch" } & PageSearchFieldsFragment) | null>;
    } | null;
};

export type PageSearchQueryVariables = Types.Exact<{
    locale: Types.Scalars["String"]["input"];
    preview?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
    id: Types.Scalars["String"]["input"];
}>;

export type PageSearchQuery = {
    __typename?: "Query";
    page?: ({ __typename?: "PageSearch" } & PageSearchFieldsFragment) | null;
};

export const PageSearchLinkToFieldsFragmentDoc = `
    fragment PageSearchLinkToFields on PageSearch {
  ...ComponentReferenceFields
  title
  linkTitle
}
    `;
export const PageSearchFieldsFragmentDoc = `
    fragment PageSearchFields on PageSearch {
  ...PageSearchLinkToFields
  modulesCollection(limit: 20) {
    __typename
    items {
      __typename
      ... on Entry {
        sys {
          id
        }
      }
    }
  }
  partnerSet {
    ...PartnerSetFields
  }
  seoMetaDescription
  robotFollow
  robotIndex
}
    `;
export const PageSearchCollectionDocument = `
    query PageSearchCollection($locale: String!, $preview: Boolean) {
  pageSearchCollection(
    limit: 1
    locale: $locale
    preview: $preview
    where: {title_exists: true}
  ) {
    items {
      ...PageSearchFields
    }
  }
}
    ${PageSearchFieldsFragmentDoc}
${PageSearchLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageSearchCollectionQuery = <TData = PageSearchCollectionQuery, TError = unknown>(
    variables: PageSearchCollectionQueryVariables,
    options?: Omit<UseQueryOptions<PageSearchCollectionQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageSearchCollectionQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageSearchCollectionQuery, TError, TData>({
        queryKey: ["PageSearchCollection", variables],
        queryFn: customFetcher<PageSearchCollectionQuery, PageSearchCollectionQueryVariables>(
            PageSearchCollectionDocument,
            variables
        ),
        ...options,
    });
};

usePageSearchCollectionQuery.getKey = (variables: PageSearchCollectionQueryVariables) => [
    "PageSearchCollection",
    variables,
];

usePageSearchCollectionQuery.fetcher = (
    variables: PageSearchCollectionQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageSearchCollectionQuery, PageSearchCollectionQueryVariables>(
        PageSearchCollectionDocument,
        variables,
        options
    );

export const PageSearchDocument = `
    query PageSearch($locale: String!, $preview: Boolean, $id: String!) {
  page: pageSearch(id: $id, locale: $locale, preview: $preview) {
    ...PageSearchFields
  }
}
    ${PageSearchFieldsFragmentDoc}
${PageSearchLinkToFieldsFragmentDoc}
${ComponentReferenceFieldsFragmentDoc}
${PartnerSetFieldsFragmentDoc}
${PartnerFieldsFragmentDoc}`;

export const usePageSearchQuery = <TData = PageSearchQuery, TError = unknown>(
    variables: PageSearchQueryVariables,
    options?: Omit<UseQueryOptions<PageSearchQuery, TError, TData>, "queryKey"> & {
        queryKey?: UseQueryOptions<PageSearchQuery, TError, TData>["queryKey"];
    }
) => {
    return useQuery<PageSearchQuery, TError, TData>({
        queryKey: ["PageSearch", variables],
        queryFn: customFetcher<PageSearchQuery, PageSearchQueryVariables>(
            PageSearchDocument,
            variables
        ),
        ...options,
    });
};

usePageSearchQuery.getKey = (variables: PageSearchQueryVariables) => ["PageSearch", variables];

usePageSearchQuery.fetcher = (
    variables: PageSearchQueryVariables,
    options?: RequestInit["headers"]
) =>
    customFetcher<PageSearchQuery, PageSearchQueryVariables>(
        PageSearchDocument,
        variables,
        options
    );
