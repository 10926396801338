import dynamic from "next/dynamic";
import { WrapperContainer } from "@/components/wrapper-container";
import type { ModuleListenToTheEngineFieldsFragment } from "./__generated/module-listen-to-the-engine.contentful.generated";
import { AspectRatio, Box, Flex, Grid, GridItem, Heading, Text } from "@project/ui";
import { gridTemplateColumns } from "@project/ui/src/theme/global-styles";

const LazyVideoAndLottieWrapper = dynamic(
    () => import("./video-and-lottie-wrapper").then(async (mod) => mod.VideoAndLottieWrapper),
    {
        ssr: true,
        loading: () => <Heading size="headingXXLarge">Loading Component Code...</Heading>,
    }
);

type ModuleListenToTheEngineProps = ModuleListenToTheEngineFieldsFragment;

export const ModuleListenToTheEngine = ({
    description,
    landscapeVideo,
    portraitVideo,
    pressAndHoldForSoundButtonLabel,
    lottieJson,
    loadingText,
}: ModuleListenToTheEngineProps) => {
    return (
        <AspectRatio
            ratio={["9:16", "9:16", "16:9"]}
            bgColor="porscheBlack"
            color="white"
            maxHeight="100svh"
            width="full"
        >
            <Box>
                {/* content */}
                <WrapperContainer position="relative" zIndex={1} inset={0}>
                    <Grid py={{ base: 8, l: 10 }} templateColumns={gridTemplateColumns}>
                        <GridItem colStart={1} colEnd={{ base: 3, l: 5 }}>
                            <Text size="medium">{description}</Text>
                        </GridItem>
                    </Grid>
                </WrapperContainer>

                {/* media & animations */}
                <Flex
                    as="figure"
                    alignItems="center"
                    justifyContent="center"
                    position="absolute"
                    zIndex={0}
                    inset={0}
                    width="100%"
                    height="100%"
                >
                    <LazyVideoAndLottieWrapper
                        landscapeVideo={landscapeVideo}
                        portraitVideo={portraitVideo}
                        lottieJson={lottieJson}
                        pressAndHoldForSoundButtonLabel={pressAndHoldForSoundButtonLabel}
                        loadingText={loadingText}
                    />
                </Flex>
            </Box>
        </AspectRatio>
    );
};
